<h2 mat-dialog-title>Register Local Credential</h2>
<form [formGroup]="localForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required>
      <mat-error *ngIf="localForm.get('username').hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <!-- Show passwordOptions only if isForSomeoneElse is true -->
    <mat-form-field *ngIf="isForSomeoneElse">
      <mat-label>Password Options</mat-label>
      <mat-select formControlName="passwordOptions">
        <mat-option value="customPassword">Set a custom Password</mat-option>
        <mat-option value="letTheUserChooseOne">Let the user choose one</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Show password field based on the conditions defined in updatePasswordFieldVisibility() -->
    <mat-form-field *ngIf="!isForSomeoneElse || localForm.get('passwordOptions').value === 'customPassword'">
      <mat-hint>The password should be at least 12 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 following special character: !?=#*$&#64;+-.</mat-hint>
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" required>
      <mat-error *ngIf="localForm.get('password').hasError('weakPassword')">Please choose a stronger password</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="localForm.invalid">Submit</button>
  </mat-dialog-actions>
</form>
