@if ($has_permission()) {
<div>
  <h2>AI Form Filler</h2>
</div>
<div>
  @if ($recognitionState().alertMessage) {
    <div>
      <p>{{ $recognitionState().alertMessage }}</p>
    </div>
  }
  @if ($recognitionState().state !== 'unavailable') {
    <section>
      <button *ngIf="$recognitionState().buttonLabel" [disabled]="$isButtonDisabled()" type="button" id="main-btn" (click)="handleMainBtnClick()">{{ $recognitionState().buttonLabel }}</button>
    </section>
  }
</div>
}