(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('accounts', {
        url: '/',
        abstract: true,
        templateUrl: 'app/layout/templates/1-column.html',
        resolve: {
          appInit: ['SetupService', function(Setup) {
            return Setup.initApp();
          }]
        }
      })

      .state('accounts.login', {
        url: 'login',
        views: {
          content: {
            templateUrl: 'app/components/accounts/login.html',
            controller: 'LoginController',
            controllerAs: 'loginCtrl'
          }
        },
        data: {
          auth: false
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupLogin();
          }]
        }
      })

      .state('accounts.pin', {
        url: 'pin',
        views: {
          content: {
            templateUrl: 'app/components/accounts/pin.html',
            controller: 'PinController',
            controllerAs: 'loginCtrl'
          }
        },
        data: {
          auth: false
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupLogin();
          }]
        }
      })

      .state('accounts.logout', {
        url: 'logout',
        views: {
          content: {
            template: ' ',
            controller: 'LogOutController',
            controllerAs: 'loginCtrl'
          }
        }
      })

      .state('accounts.loggedout', {
        url: 'loggedout',
        views: {
          content: {
            templateUrl: 'app/components/accounts/loggedout.html',
            controller: 'LoggedOutController',
            controllerAs: 'loginCtrl'
          }
        },
        data: {
          auth: false
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupLogin();
          }]
        }
      })

      .state('accounts.register', {
        url: 'register',
        views: {
          content: {
            templateUrl: 'app/components/accounts/register.html',
            controller: 'RegisterController',
            controllerAs: 'ctrl'
          }
        },
        data: {
          auth: false
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupLogin();
          }],
          verifyToken: ['db', '$q', '$location', function(_db, $q) {
            return $q.reject({
              status: 301,
              message: 'This is no longer available, please use the \'Fill in quickly\' link. ' +
              'Thank you'
            });
          }]
        }
      })

      .state('accounts.auto-register', {
        url: 'auto-register',
        views: {
          content: {
            templateUrl: 'app/components/accounts/auto-register.html',
            controller: 'AutoRegisterController',
            controllerAs: 'ctrl'
          }
        },
        data: {
          auth: false
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupRegistering();
          }]
        }
      })

      .state('accounts.org-chooser', {
        url: 'org-chooser',
        views: {
          content: {
            templateUrl: 'app/components/accounts/org-chooser.html',
            controller: 'OrgChooserController',
            controllerAs: 'orgChooserCtrl'
          }
        },
        resolve: {
          db: ['SetupService', function(Setup) {
            return Setup.setupAppChooser();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.accounts')
    .config(config);
})();
