(function() {
  'use strict';

  function UserEditController($q, $rootScope, $scope, $stateParams, Auth, Security, Users, Utils) {
    var ctrl = this;

    var username = $stateParams.user;
    function hasPerm(action) {
      var auth;
      var permission;
      if (action === 'review') {
        permission = 'users.review';
      }

      if (!permission) {
        return $q.reject();
      }

      if (username === Auth.currentUser()) {
        auth = Security.hasPermission(permission + '.own');
      } else {
        auth = Security.hasPermissionFor(permission, username);
      }

      return auth;
    }

    ctrl.current = 'basic';
    ctrl.items = [
      { id: 'basic', name: 'Basic Information' },
      { id: 'emails', name: 'Emails' },
      { id: 'details', name: 'Additional details' },
      { id: 'roles', name: 'Access Control' }
    ];

    ctrl.goTo = function(itemId) {
      if (ctrl.currentForm !== undefined && ctrl.currentForm.$dirty) {
        Utils.confirm({
          title: 'You will lose unsaved changes if you navigate away from this tab',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        })
        .then(function() {
          ctrl.current = itemId;
          ctrl.currentForm = undefined;
        });
      } else {
        ctrl.current = itemId;
      }
    };

    if ($stateParams.goTo) {
      ctrl.goTo($stateParams.goTo);
    }

    hasPerm('review').then(function() {
      ctrl.canReview = true;
    });

    ctrl.profile = Users.remoteUser || Auth.currentUser();
    ctrl.remoteUser = Users.remoteUser;
    Users.find(ctrl.profile)
      .then(function(profile) {
        ctrl.profile = profile;
        if (Users.remoteUser) {
          var fullName = _.trim(
            (ctrl.profile.firstName || '') + ' ' + (ctrl.profile.lastName || '')
          );
          Utils.setPageTitle('Edit ' + fullName + '\'s profile');
        } else {
          Utils.setPageTitle('Edit my profile');
        }
      })
      .finally(function() {
        ctrl.loaded = true;
      });

    ctrl.approve = function() {
      // The buttons will be disabled but the digest will not get
      // double click
      if (ctrl.formIsSubmitted) {
        return;
      }

      ctrl.formIsSubmitted = true;

      ctrl.profile.type = 'user'; // Make sure this is user and not something else
      ctrl.profile._id = ctrl.profile._id ? ctrl.profile._id : Utils.guid(); // Generate an ID
      return Users.updateState(ctrl.profile, 'user_approve')
        .then(function() {
          return Users.find(ctrl.profile.username, { noCache: true });
        })
        .then(function() {
          $rootScope.$broadcast('KZRemoteUserChanged');
          // todo: redirection is not working
          Utils.go(ctrl.nextPage, ctrl.nextParams, { tryBack: true });
        })
        .catch(Utils.showError)
        .finally(function() {
          ctrl.formIsSubmitted = false;
        });
    };

    $scope.$on('KZUserFormChanged', function(_evt, args) {
      ctrl.currentForm = args.form;
    });
  }

  UserEditController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$stateParams',
    'AuthService',
    'SecurityService',
    'UsersService',
    'UtilsService'
  ];

  angular.module('component.users')
    .controller('UserEditController', UserEditController);
})();
