<h2 mat-dialog-title>Change Password</h2>
<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Old Password</mat-label>
      <input matInput type="password" formControlName="oldPassword" required>
      <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('required')">Old Password is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>New Password</mat-label>
      <mat-hint>The password should be at least 12 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 following special character: !?=#*$&#64;+-.</mat-hint>
      <input matInput type="password" formControlName="newPassword" required>
      <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">New Password is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Re-type New Password</mat-label>
      <input matInput type="password" formControlName="confirmPassword" required>
      <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">Re-type New Password is required</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="changePasswordForm.invalid">Submit</button>
  </mat-dialog-actions>
</form>
