<div>
  <h3>Email OTP Configuration</h3>
  <button (click)="initEmailOTPRegistration()" *ngIf="!isOtpSent">Send OTP</button>
  <div *ngIf="isOtpSent">
    <p>{{ verificationMessage }}</p>
    <form [formGroup]="otpForm" (ngSubmit)="completeEmailOTPRegistration()">
      <label for="code">Enter OTP:</label>
      <input id="code" formControlName="code" type="text" />
      <button type="submit">Verify OTP</button>
    </form>
  </div>
  <p>{{ verificationMessage }}</p>
</div>
