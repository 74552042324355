import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, computed, effect, inject, signal } from '@angular/core';
import { AiFormFillerService } from './ai-form-filler.service';
import { CommonModule } from '@angular/common';
import { SecurityService } from '@fry/core/security.service';

@Component({
  selector: 'app-ai-form-filler-aside',
  templateUrl: './ai-form-filler-aside.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
  ],
  providers: [
    AiFormFillerService,
  ]
})
export class AiFormFillerAsideComponent implements OnInit {

  @Input() formDefinition!: any;
  @Output() formDataChange = new EventEmitter<any>();

  $has_permission = signal<boolean>(false);

  private security = inject(SecurityService);

  private aiFormFillerService = inject(AiFormFillerService);
  public $recognitionState = this.aiFormFillerService.$recognitionState;

  public $isButtonDisabled = computed(() => this.$recognitionState().state === 'processing');

  constructor() {
    effect(() => {
      this.formDataChange.emit(this.aiFormFillerService.$formData());
    });
  }

  ngOnInit() {
    this.security.hasPermission('features.ai-form-filler').then(has_permission => {
        if (has_permission) {
            this.$has_permission.set(true);
            return this.aiFormFillerService.initSpeachRecognition();
        }
    });
  }

  ngOnChanges() {
    console.log("Form definition changed:", this.formDefinition);
    this.aiFormFillerService.setFormSpec(this.formDefinition);
  }

  handleMainBtnClick() {
    if (this.$recognitionState().state === 'listening') {
      this.aiFormFillerService.stopSpeechRecognition();
    } else {
      this.aiFormFillerService.startSpeechRecognition();
    }
  }
}