import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";


@Component({
  selector: 'app-proxy-credential-setup-dialog',
  templateUrl: './proxy-credential-setup-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormField,
    MatSelect,
    MatOption,
    MatDialogActions,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    MatLabel,
    MatError
  ]
})
export class ProxyCredentialSetupDialogComponent implements OnInit {
  proxyForm: FormGroup;
  isForSomeoneElse: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ProxyCredentialSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isForSomeoneElse: boolean }
  ) {
    this.isForSomeoneElse = data.isForSomeoneElse;
  }

  ngOnInit(): void {
    this.proxyForm = this.fb.group({
      username: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.proxyForm.valid) {
      this.dialogRef.close(this.proxyForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
