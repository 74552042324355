<div>
  <h2>Protect your account with 2-step verification</h2>

  <!-- Step 1: Select MFA Method -->
  <div *ngIf="currentStep === 1">
    <eas-method-selection (methodSelected)="onMethodSelected($event)"></eas-method-selection>
  </div>

  <!-- Step 2: Configuration for selected MFA method -->
  <div *ngIf="currentStep === 2">
    <ng-container [ngSwitch]="selectedMethod">
      <eas-email-registration *ngSwitchCase="'email_otp'" (next)="nextStep()" (back)="previousStep()" credentialUsername="{{credentialUsername}}"></eas-email-registration>
      <eas-totp-registration *ngSwitchCase="'totp'" (next)="nextStep()" (back)="previousStep()" credentialUsername="{{credentialUsername}}"></eas-totp-registration>
      <eas-passkey-registration *ngSwitchCase="'passkey'" (next)="nextStep()" (back)="previousStep()" credentialUsername="{{credentialUsername}}"></eas-passkey-registration>
    </ng-container>
  </div>

  <!-- Step 3: Confirm Code -->
  <div *ngIf="currentStep === 3">
    <eas-code-confirmation (reset)="resetSetup()" (finish)="nextStep()"></eas-code-confirmation>
  </div>

  <!-- Setup Completion -->
  <div *ngIf="currentStep === 4">
    <p>Setup completed! Your account is now protected with multi-factor authentication.</p>
  </div>
</div>
