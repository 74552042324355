import { Directive, ElementRef, inject, Input } from "@angular/core";
import { RouteService, upgradeProviders } from "./route.service";

@Directive({
    selector: "a[routerLinkJs]",
    standalone: true,
    providers: [
        ...upgradeProviders
    ]
})
export class RouteLinkJsDirective {

    @Input() routerLinkJs: string;
    @Input() routerParamsJs: any;

    private el = inject(ElementRef);
    private routeService = inject(RouteService);

    constructor() {
    }

    ngOnChanges() {
        const url = this.routeService.href(this.routerLinkJs, this.routerParamsJs);
        this.el.nativeElement.href = url;
    }

}
