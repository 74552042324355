import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import { MfaSetupComponent } from "@fry/system/account/mfa/mfa-setup.component";
import { MatButton } from "@angular/material/button";

@Component({
  selector: 'app-mfa-setup-dialog',
  template: `
    <h2 mat-dialog-title>Register New MFA</h2>
    <mat-dialog-content>
      <eas-mfa-setup credentialUsername="{{credentialUsername}}"></eas-mfa-setup>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [
    MatDialogContent,
    MfaSetupComponent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle
  ]
})
export class MfaSetupDialogComponent {
  credentialUsername: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { credentialUsername: string }
  ) {
    this.credentialUsername = data.credentialUsername;
  }

}
