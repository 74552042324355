import { Component, computed, inject } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from "@angular/material/card";
import { MatDivider } from "@angular/material/divider";
import { MatList, MatListItem } from "@angular/material/list";
import { remove } from "lodash";
import { MFA, READABLE_MFA_METHODS } from "@fry/system/account/mfa/mfa";
import { MFAService } from "@fry/system/account/mfa/mfa.service";
import { AuthService } from "@fry/core/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MfaSetupDialogComponent } from "@fry/system/account/mfa/mfa-setup-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RouteService, upgradeProviders } from '@fry/upgrade/route.service';

@Component({
  selector: 'eas-mfas',
  standalone: true,
  templateUrl: './mfas.component.html',
  imports: [
    CommonModule,
    MatCard,
    MatCardTitle,
    MatCardHeader,
    MatCardContent,
    MatDivider,
    MatList,
    MatListItem
  ],
  providers: [
    ...upgradeProviders
  ]

})
export class MFAsComponent {
  private routeService = inject(RouteService);
  private authService = inject(AuthService);
  private credentialUsername = this.routeService.getParam('credentialUsername');
  private _accountId = this.routeService.getParam('remoteUser');
  private accountId = computed(() => this._accountId() || this.authService.currentUser());

  public isUserRemote = computed(() => this._accountId() !== null);

  mfas: MFA[] = [];
  readableName = READABLE_MFA_METHODS

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private MFAService: MFAService,
  ) {}

  ngOnInit() {
    console.log("MFAsComponent ngOnInit", this.accountId(), this.credentialUsername());
    this.loadUserRegisteredMFAs();
  }

  protected readonly remove = remove;

  private async loadUserRegisteredMFAs() {
    const response = await this.MFAService.getRegisteredMFAs(this.credentialUsername(), this.accountId());
    if (response.state !== "ok") {
      return;
    }

    this.mfas = response.data;
  }

  setUpNewMFA() {
    this.dialog.open(MfaSetupDialogComponent, {
      width: '400px',
      disableClose: true, // Optional: disables closing the dialog by clicking outside
      data: { credentialUsername: this.credentialUsername() }
    });
  }

  async deleteMFA(mfaId: string) {
    const response = await this.MFAService.deleteMFA(this.credentialUsername(), mfaId, this.accountId());
    if (response.state !== "ok") {
      this.snackBar.open('Failed to delete MFA', 'Close', { duration: 3000 });
      return;
    }
    this.snackBar.open('MFA deleted successfully', 'Close', { duration: 3000 });
    this.loadUserRegisteredMFAs();
  }
}
