import { inject, Injectable } from '@angular/core';
import { Api, Response } from "@fry/core/api";
import {
  ApiCredential, CredentialTypes,
  LocalCredential,
  ProxyCredential
} from "@fry/system/account/credential/credential";


@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private api = inject(Api);

  async getCredentials(accountId: string): Promise<Response<(LocalCredential | ProxyCredential | ApiCredential)[]>> {
    return this.api.get<(LocalCredential | ProxyCredential | ApiCredential)[]>(`/v2/accounts/${accountId}/credentials`);
  }

  async addCredential(accountId: string, credential: LocalCredential | ProxyCredential | ApiCredential): Promise<Response<(LocalCredential | ProxyCredential | ApiCredential)>> {
    return this.api.post<(LocalCredential | ProxyCredential | ApiCredential)>(`/v2/accounts/${accountId}/credentials`, credential);
  }

  async editCredentialUsername(accountId: string, credentialType: CredentialTypes.LOCAL | CredentialTypes.PROXY, currentCredentialUsername: string, newCredentialUsername: string): Promise<Response<void>> {
    return this.api.put<void>(`/v2/accounts/${accountId}/credentials/${credentialType}/${currentCredentialUsername}`, { username: newCredentialUsername });
  }

  async changePassword(accountId: string, credentialUsername: string, oldPassword:string, newPassword: string): Promise<Response<void>> {
    const credentialType = CredentialTypes.LOCAL;
    return this.api.put<void>(`/v2/accounts/${accountId}/credentials/${credentialType}/${credentialUsername}`, { oldPassword, newPassword });
  }

  async sendPasswordResetEmail(accountId: string, credentialUsername: string): Promise<Response<void>> {
    return this.api.post<void>(`/v2/accounts/${accountId}/credentials/local/${credentialUsername}/password-reset`);
  }

  async deleteLoginCredential(accountId: string, credential: LocalCredential | ProxyCredential): Promise<Response<void>> {
    return this.api.delete<void>(`/v2/accounts/${accountId}/credentials/${credential.type}/${credential.username}`);
  }

  async deleteApiCredential(accountId: string, credentialUsername: string): Promise<Response<void>> {
    return this.api.delete<void>(`/v2/accounts/${accountId}/credentials/api/${credentialUsername}`);
  }
}
