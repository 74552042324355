export enum MFAMethodsEnum {
  EMAIL_OTP = 'email_otp',
  PASSKEY = 'passkey',
  TOTP = 'totp'
}

export const READABLE_MFA_METHODS = {
  [MFAMethodsEnum.EMAIL_OTP]: 'Email',
  [MFAMethodsEnum.PASSKEY]: 'Pass key',
  [MFAMethodsEnum.TOTP]: 'Authenticator'
};

export interface MFA {
  id: string;                // Unique ID for the MFA
  type: MFAMethodsEnum;      // Type of MFA
  createdAt: string;         // Creation timestamp
}
