import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from "@angular/common";
import { OrganisationService } from "@fry/core/organisation.service";


export interface MFAMethod {
  id: 'email_otp' | 'totp' | 'passkey',
  name: 'Email' | 'Authenticator' | 'Passkey',
}


@Component({
  selector: 'eas-method-selection',
  templateUrl: './method-selection.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class MethodSelectionComponent {
  @Output() methodSelected = new EventEmitter<string>();

  availableMethods: MFAMethod[] = [];

  constructor(private organisationService: OrganisationService) {}

  ngOnInit(): void {
    this.getAvailableMethods();
  }

  async getAvailableMethods() {
    const organisation = this.organisationService.currentOrganisation();
    this.availableMethods = this.idsToMFAMethod(organisation.enabledMFAs); // fixme
  }

  selectMethod(method: string) {
    this.methodSelected.emit(method);
  }

  private idsToMFAMethod(ids: string[]): MFAMethod[] {
    return ids.map(id => {
      switch (id) {
        case 'email_otp':
          return { id: 'email_otp', name: 'Email' };
        case 'totp':
          return { id: 'totp', name: 'Authenticator' };
        case 'passkey':
          return { id: 'passkey', name: 'Passkey' };
        default:
          return { id: id as any, name: id as any };
      }
    });
  }
}
