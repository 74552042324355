import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from "@angular/core";
import { OidcService } from "./core/oidc.service";
import epf from '../../app';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import angular from 'angular';
import { ClusterService } from "./core/cluster.service";
import { OrganisationService } from "./core/organisation.service";


@Component({
  selector: "app-main",
  templateUrl: './main.component.html',
  styles: [],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ UpgradeModule, ],
  providers: [
  ]
})
export class MainComponent implements OnInit {

  @ViewChild('kaizen') kaizenEl: ElementRef;

  private upgrade = inject(UpgradeModule);
  private oidc = inject(OidcService);
  private cluster = inject(ClusterService);
  private organisation = inject(OrganisationService);

  async ngOnInit() {
    const user = await this.oidc.init({}, { relogin: true })
    console.log("OIDC ok", user);
    if (user !== null) {
      console.log("OIDC refresh", user.refresh_token);
    }
    await this.cluster.loadProfileOrganisations();
    await this.organisation.fetchOrganisation();
    console.log('Current user: ', this.oidc.currentUserSignal());
    console.log('Current eas user: ', this.oidc.currentUser());
    this.startKaizen();
  }

  startKaizen(): void {
    setAngularJSGlobal(angular);
    this.upgrade.bootstrap(this.kaizenEl.nativeElement, [epf], { strictDi: true });
  }
}