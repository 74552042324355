<section class="wrapper">
  <header class="row">
    <h1>2-Step Verification</h1>
  </header>

  <div class="row">
    <div class="col-xs-12">
      <p class="box box-warning">
        Your organisation has enforced 2-step verification on your account. You cannot turn it off, but you can add or change your second steps.
      </p>

      <div class="row row-bordered" *ngFor="let mfa of mfas;">
        <div class="col-xs-8">
          <p>{{ readableName[mfa.type] }}</p>
        </div>
        <div class="col-xs-4 text-right">
          <button class="btn btn-sm btn-danger" (click)="deleteMFA(mfa.id)">Remove</button>
        </div>
      </div>

      <div class="add-credential">
        <p><button class="btn btn-primary" (click)="setUpNewMFA()" *ngIf="!isUserRemote()">Register a new MFA</button></p>
      </div>
    </div>
  </div>

</section>
