(function() {
  'use strict';

  function AccountsService(Api) {
    var service = {};

    service.find = function(accountId) {
      return Api.get('useraccounts', {}, { account_id: accountId });
    };

    service.getIntegrations = function() {
      return { org_rcpch: { autoGenerateUsername: true, autoGeneratePassword: false } };
    };

    return service;
  }

  AccountsService.$inject = ['ApiService'];

  angular.module('component.accounts')
    .factory('AccountsService', AccountsService);
})();
