import { Component, Input } from '@angular/core';
import { MFAService } from './mfa.service';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'eas-passkey-registration',
  templateUrl: './passkey-registration.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule]
})
export class PasskeyRegistrationComponent {
  @Input() credentialUsername: string;

  mfaId: string;
  registrationMessage: string = '';
  isRegistered: boolean = false;

  constructor(private mfaService: MFAService) {}

  async registerPasskey(): Promise<void> {
    try {
      const response = await this.mfaService.initPasskeyRegistration(
        this.credentialUsername
      );
      if (response.state === 'error') {
        console.error('Error sending passkey challenge');
        this.registrationMessage = 'Failed to send passkey challenge. Please try again later.';
        return;
      }

      const publicKeyOptions = response.data;
      const passkey = await navigator.credentials.create({
        publicKey: publicKeyOptions
      });

      const completeRegistrationResponse = await this.mfaService.completePasskeyRegistration(
        this.credentialUsername,
        this.mfaId,
        passkey
      );

      if (completeRegistrationResponse.state === 'error') {
        console.error('Error sending passkey challenge:', completeRegistrationResponse.error);
        this.registrationMessage = 'Failed to send passkey challenge. Please try again later.';
        return;
      }

      this.isRegistered = completeRegistrationResponse.state === 'ok';
      this.registrationMessage = this.isRegistered
        ? 'Passkey registered successfully!'
        : 'Failed to register passkey. Please try again.';
    } catch (error) {
      console.error('Passkey registration failed:', error);
      this.registrationMessage = 'An error occurred during registration. Please try again.';
    }
  }
}
