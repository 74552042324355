import { downgradeComponent } from '@angular/upgrade/static';
import { AiFormFillerAsideComponent } from '@fry/ai-form-filler/ai-form-filler-aside.component';

(function() {
  'use strict';

  angular.module('component.events', [
    'events.directives'
  ])
  .directive('aiFormFiller', downgradeComponent({
    component: AiFormFillerAsideComponent,
    inputs: ['formDefinition'],
    outputs: ['formDataChange']
  }));
})();
