<h2 mat-dialog-title>Register SSO Credential</h2>
<form [formGroup]="proxyForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required>
      <mat-error *ngIf="proxyForm.get('username').hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="proxyForm.invalid">Submit</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</form>
