(function() {
  'use strict';

  function UserCreateController(
    $state,
    Users,
    Utils,
    Security
  ) {
    var ctrl = this;

    ctrl.profile = {};
    ctrl.accounts = [];

    Utils.setPageTitle('New user');

    Security.hasPermission('credential.manage')
      .then(function() {
        ctrl.canManageAccounts = true;
      })
      .catch(function() {
        ctrl.canManageAccounts = false;
      });

    Security.hasPermission('roles.assign')
      .then(function() {
        ctrl.canAssignRoles = true;
      })
      .catch(function() {
        ctrl.canAssignRoles = false;
      });

    ctrl.formIsSubmitted = false;
    ctrl.create = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (isValid) {
        var usernames = {};
        var errors = false;
        _.forEach(ctrl.accounts, function(account) {
          if (_.isUndefined(usernames[account.type])) {
            usernames[account.type] = [];
          } else if (_.indexOf(usernames[account.type], account.username) > -1) {
            Utils.showError({ message: 'Duplicates of usernames: ' + account.username });
            errors = true;
            return;
          }

          usernames[account.type].push(account.username);
        });

        if (errors) {
          ctrl.formIsSubmitted = false;
          return;
        }

        ctrl.profile.accounts = ctrl.accounts;
        Users.create(ctrl.profile)
          .then(function(newUser) {
            ctrl.formIsSubmitted = false;
            $state.go('dashboard.summary', { user: newUser.user });
          }).catch(function(error) {
            ctrl.formIsSubmitted = false;
            console.log(error);
            Utils.swal({
              title: error.message,
              type: 'error'
            });
          });
      } else {
        ctrl.formIsSubmitted = false;
      }
    };
  }

  UserCreateController.$inject = [
    '$state',
    'UsersService',
    'UtilsService',
    'SecurityService'
  ];

  angular.module('component.users')
    .controller('UserCreateController', UserCreateController);
})();
