(function() {
  'use strict';

  var apiPaths = {
    useraccounts: '/accounts/{account_id}',
    verify_token: '/accounts/verify_token/{token}', // todo: kill me?
    register: '/accounts/register',
    autoregister: '/accounts/auto_register',
    unique: '/accounts/unique',
    login: '/accounts/login',
    has_permission_for: '/accounts/has_permission_for',
    has_role_for: '/accounts/has_role_for',

    es_users: '/elastic/users/',
    es_usersstub: '/elastic/usersstub/',
    es_eventtypes: '/elastic/event_types/',
    es_eventtypes_ids: '/elastic/event_types_ids/',
    es_events: '/elastic/events/',
    es_events_ids: '/elastic/events_ids/',
    es_events_own_ids: '/elastic/events_own_ids/',
    es_pending_sections: '/elastic/pending_sections/',
    es_goals: '/elastic/goals/',
    es_goals_own_ids: '/elastic/goals_own_ids/',
    es_goals_ids: '/elastic/goals_ids/',
    es_celerytasks_ids: '/elastic/celerytasks_ids/',

    msg_submit: '/messages/submit/',

    users: '/users/',
    users_count_by_role: '/users/count-by-role/{role_id}',
    user_update: '/users/update',
    usersstub: '/users/stub/',
    usersupdatestate: '/users/update-state/',
    userevents: '/users/{username}/events/',
    usercurrentevents: '/users/{username}/currentevents/',
    usertargetlinkedevents:
      '/users/{username}/target-linked-events/{goal_id}/{target_id}/{period_id}',
    userreports: '/users/{username}/reports/',
    usergoalsinsection: '/users/{username}/goals-in-section/{event_id}/{section_id}',
    usergoals: '/users/{username}/goals',
    usergoalsbyids: '/users/{username}/goals-by-ids/{goals_ids}',
    userothertargetslinked: '/users/{username}/other-targets-linked/{goal_id}/{target_id}',
    userprivateevents: '/users/{username}/private_events/',

    // organisations
    organisations: '/organisations/',
    logins: '/organisations/logins',
    cluster_available_languages: '/organisations/cluster_available_languages',
    userOrganisations: '/organisations/for_current_user',

    roles: '/roles/',
    role_delete: '/roles/delete/{role_id}',
    events: '/events/',
    eventblueprints: '/events/blueprints/',
    events_ids: '/events/ids',
    eventsections: '/eventsections/',
    eventsearch: '/events/search/',
    eventextras: '/events/{event_id}/extra/',
    eventsectionextras: '/eventsections/{eventsection_id}/extra/',
    eventallextras: '/events/extras/',
    eventrequests: '/events/token/',
    eventdecrypt: '/events/decrypt/',
    eventsectiondecrypt: '/eventsections/decrypt/',
    isnextresponsiblepersonvalid: '/eventsections/is-next-responsible-person-valid/{pk}',
    nextresponsiblepersonupdate: '/eventsections/next-responsible-person-valid-update/{pk}',
    eventaddlog: '/events/add_log/{pk}',
    uniqueeventtypes: '/events/uniqueeventtypes/{username}',
    eventtypes: '/event_types/',
    eventtypes_ids: '/event_types/ids',
    eventtypesavailablefor: '/event_types/available-for/{username}',
    eventtypes_options: '/event_types/field_options',
    eventtypes_goal_options: '/event_types/goal_options',
    event_sections: '/events/{event_id}/section/{section_id}',
    event_sections_counts: '/events/{event_id}/section/{section_id}/counts',
    event_sections_meta_data: '/events/{event_id}/section/{section_id}/meta_data',
    event_sections_tokens: '/events/{event_id}/section/{section_id}/tokens',
    sections: '/pending_sections/',
    section_accept: '/pending_sections/{section_id}/accept',
    section_reject: '/pending_sections/{section_id}/reject',
    section_reopen: '/pending_sections/{section_id}/reopen',
    section_submit: '/forms/submit/{token}',

    token_url: '/a/{token}',

    run_report_field: '/event-reports/{event_id}/run_report_field',

    reports_run: '/reports/run/{report_id}',
    reports_download: '/reports/download/{report_id}',
    reports_generate_task: '/reports/generate/task/{report_id}',

    goals: '/goals/',
    goals_ids: '/goals/ids',
    target_link_event: '/goals/{goal}/{target}/link',
    addgoalstogoalset: '/goals/add-goals-to-goal-set',
    updategoalsetduedate: '/goals/update-goalset-due-date',
    updategoalsetperiods: '/goals/update-goalset-periods',
    goals_unique_eventtypes: '/goals/unique-eventtypes',
    target_progression_cache: '/goals/{user_id}/target-progression-cache/{cache_id}',
    goalsetsummary: '/goals/goalset-summary/{event_id}/{section_id}',

    celerytask_ids: '/celerytasks/ids',
    capture_log: '/capture-log'
  };

  angular.module('blocks.api')
    .constant('API_PATHS', apiPaths);
})();
