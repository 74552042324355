import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { CredentialTypes } from "@fry/system/account/credential/credential";
import { AuthService } from "@fry/core/auth.service";


@Component({
  selector: 'app-edit-username-credential-dialog',
  templateUrl: './edit-username-credential-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormField,
    MatSelect,
    MatOption,
    MatDialogActions,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    MatLabel,
    MatError
  ]
})
export class EditUsernameCredentialDialogComponent implements OnInit {
  editUsernameForm: FormGroup;
  currentCredentialUsername: string;
  dialogTitle: string;
  label: string;

  integrations = { org_fry: { autoGenerateUsername: true, autoGeneratePassword: false } };
  orgHasintegration = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditUsernameCredentialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentCredentialUsername: string, credentialType: CredentialTypes }
  ) {
    this.currentCredentialUsername = data.currentCredentialUsername;
    if (data.credentialType === CredentialTypes.LOCAL) {
      this.dialogTitle = 'Edit local credential\'s username';
      this.label = 'New Local Username';
    } else if (data.credentialType === CredentialTypes.PROXY) {
      this.dialogTitle = 'Edit SSO credential\'s ID';
      this.label = 'New SSO ID';

      this.orgHasintegration = !!this.integrations[this.authService.currentOrganisation()];
    }
  }

  ngOnInit(): void {
    this.editUsernameForm = this.fb.group({
      useIntegration: [],
      newUsername: [this.currentCredentialUsername, Validators.required]
    });
  }

  onSubmit() {
    if (this.editUsernameForm.valid) {
      this.dialogRef.close(this.editUsernameForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
