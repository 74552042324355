import { Injectable, computed, inject, signal } from '@angular/core';
import { AccessTokenCallback, User, UserLoadedCallback, UserManager } from 'oidc-client-ts';
import { BroadcastService } from './broadcast.service';
import { NetworkService } from './network.service';

export interface OidcOptions {
    scope?: string;
    resource?: string;
    organisation?: string;
}

export interface EasUser {
    username: string;
    usernameHeader: string;
    login_type: 'oidc';
    masqueradeBy?: string;
    originalProfile: {
        username: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        fullName?: string;
    },
    user: User;

}

export interface ApiInfo {
    token: string;
    organisation: string;
    username: string;
}

@Injectable({
    providedIn: 'root'
})
export class OidcService {

    readonly $currentOrganisation = signal<string | null>(null);
    readonly currentUserSignal = signal<User>(null);
    readonly currentUser = computed(() => this.userToEasUser(this.currentUserSignal()));
    readonly apiInfo = computed(() => this.getApiInfo(this.currentUser()));

    private _organisation: string;

    private _renewTokenPromise: Promise<User> | null = null;
    private userLoadedCallback: UserLoadedCallback = (user: User) => {
        console.log('OIDC: User loaded', user);
        if (user) {
            this.currentUserSignal.set(user);
        }
    }
    private accessTokenExpiredCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expired');
    }
    private accessTokenExpiringCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expiring');
        this.renewToken();
    }

    private callbacks = {
        UserLoaded: this.userLoadedCallback,
        AccessTokenExpired: this.accessTokenExpiredCallback,
        AccessTokenExpiring: this.accessTokenExpiringCallback,
    }

    userManager: UserManager;
    private broadcast = inject(BroadcastService);
    private network = inject(NetworkService);

    constructor() {
        const org = localStorage.getItem('currentOrganisation');
        this.setOrganisation(org);
    }

    public async init(options: OidcOptions, initOptions: {relogin: boolean}): Promise<User | null> {
        console.log('OIDC: init');
        this.userManager = this.createManager(options);
        console.log('OIDC: created');

        Object.entries(this.callbacks).forEach(([name, callback]) => {
            this.userManager.events[`add${name}`](callback);
        });

        try {
            const user = await this.userManager.getUser();
            if (!user) {
                // return user;
                if (initOptions.relogin) {
                    this.login();
                    throw new Error('User not logged in');
                }
                return null;
            } else if (user.expired) {
                return this.renewToken().catch(err => {
                    console.log(err);
                    if (initOptions.relogin) {
                        this.login();
                        throw new Error('User not logged in');
                    }
                    return null;
                });
            } else {
                const userToStore = { ...user, access_token: null, refresh_token: null };
                localStorage.setItem('oidcUser', JSON.stringify(userToStore));
                this.currentUserSignal.set(user);
                return user;
            }
        } catch (err) {
            if (this.network.isOffline()) {
                console.log('OIDC: Offline');
                const userJSON = localStorage.getItem('oidcUser');
                if (userJSON) {
                    const user = JSON.parse(userJSON);
                    this.currentUserSignal.set(user);
                    return user;
                }
                return null;
            }
            console.log("OIDC: init error", err);
            return null;
        };
    }

    public async resetManager(options: OidcOptions): Promise<User | null> {
        if (this.userManager) {
            Object.entries(this.callbacks).forEach(([name, callback]) => {
                this.userManager.events[`remove${name}`](callback);
            });
        }
        return this.userManager.removeUser().then(() => {
            this.userManager = null;
            return this.init(options, { relogin: true });
        });
    }

    createManager(options: OidcOptions): UserManager {
        var serverSettings = window['kzConfig'] ? window['kzConfig'].oidc : {};

        var scope = 'openid profile email';
        let lastScope: string;
        if (options.scope === undefined) {
            lastScope = localStorage.getItem('eas:lastScope');
            scope = lastScope || scope;
        } else {
            if (options.scope) {
                scope += ' ' + options.scope;
                window.localStorage.setItem('eas:lastScope', scope);
            } else {
                window.localStorage.removeItem('eas:lastScope');
            }
        }

        var defaultSettings = {
            automaticSilentRenew: false,
            revokeAccessTokenOnSignout: true,
            accessTokenExpiringNotificationTime: 60,
            scope: scope,
            loadUserInfo: false
        };

        const settings = {
            ...defaultSettings,
            ...serverSettings,
            scope: scope,
            resource: options.resource,
        };

        if (this.organisation) {
            settings.extraQueryParams = { kz_org: this.organisation };
        }

        console.log('OIDC', settings);
        return new UserManager(settings);
    }

    private userToEasUser(user: User): EasUser {
        console.log('OIDC: userToEasUser', user);
        if (!user) {
            return null;
        }

        const laseMatch = user.scope.match(/lase:([a-zA-Z0-9\-_]+)/);
        let username = user.profile.sub;
        let usernameHeader = user.profile.sub;
        let masqueradeBy;
        if (laseMatch) {
            username = laseMatch[1];
            usernameHeader = `${username}:lase:${user.profile.sub}`
            masqueradeBy = user.profile.sub;
        }
        return {
            username: username,
            login_type: 'oidc',
            usernameHeader: usernameHeader,
            masqueradeBy: masqueradeBy,
            originalProfile: {
                username: user.profile.sub,
                firstName: (user.profile.firstName || '') as string,
                lastName: (user.profile.lastName || '') as string,
                email: user.profile.email,
                fullName: ((user.profile.firstName || '') +
                ' ' + (user.profile.lastName || '')).trim() || user.profile.email
            },
            user: user,
        };
    }


    public async easUser(): Promise<EasUser | null> {
        const easUser = this.currentUser();
        if (easUser && easUser.user && !easUser.user.expired) {
            return easUser;
        }

        return this.user().then((user: User) => {
            return this.userToEasUser(user);
        });
    }

    public async user(): Promise<User | null> {
        console.log("OIDC: Requesting user")
        return this.userManager.getUser().then((user: User) => {
            if (user && !user.expired) {
                return user;
            } else if (user) {
                console.log('OIDC: Renew when getting user');
                return this.renewToken().then((user: User) => {
                    return user;
                }).catch(err => {
                    console.log(err);
                    return null;
                });
            } else {
                return null;
            }
        });
    }

    public login(): Promise<void> {
        var href = location.pathname + location.search + location.hash;
        sessionStorage.setItem('lastUrl', href);
        return this.userManager.signinRedirect();
    }

    public loginPopup(): Promise<User> {
        return this.userManager.signinPopup();
    }

    public renewToken(): Promise<User> {
        console.log("OIDC: Requesting renewToken");
        if (this._renewTokenPromise === null) {
            console.log("OIDC: renewToken");
            this._renewTokenPromise = this.userManager.signinSilent().then(user => {
                console.log("OIDC: renewToken ok");
                this._renewTokenPromise = null;
                return user;
            }).catch(err => {
                console.log("OIDC: renewToken err", err);
                this._renewTokenPromise = null;
                throw err;
            });
        }
        return this._renewTokenPromise;
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public loginAs(username: string): Promise<User> {
        const options: OidcOptions = {
            scope: username !== undefined ? `profile lase lase:${username}` : '',
            resource: username !== undefined ? `https://risr.global/resources/api+lase:${username}` : '',
        }
        return this.resetManager(options);
    }

    public logoutAs() {
        return this.loginAs(undefined);
    };

    public async signinCallback(): Promise<User> {
        const manager = this.createManager({});
        return await manager.signinRedirectCallback();
    }

    public async signinPopupCallback(): Promise<void> {
        const manager = this.createManager({});
        return await manager.signinPopupCallback();
    }

    public async signinSilentCallback(): Promise<void> {
        const manager = this.createManager({});
        return await manager.signinSilentCallback();
    }

    public async isLoggedIn(): Promise<boolean> {
        if (this.network.isOffline()) {
            return this.currentUserSignal() !== null;
        }

        return this.userManager.getUser().then(user => {
            return user !== null && !user.expired;
        });
    }

    public get organisation(): string {
        if (this._organisation === undefined) {
            let org;
            try {
                org = localStorage.getItem('currentOrganisation');
            } catch (err) {
                console.log('Could not get organisation from local storage', err);
                org = null;
            }

            this._organisation = org;
        }

        return this._organisation;
    }

    public setOrganisation(organisation: string | null): void {
        // FIXME - update Sentry
        this._organisation = organisation;
        try {
            if (organisation) {
                localStorage.setItem('currentOrganisation', organisation);
            } else {
                localStorage.removeItem('currentOrganisation');
            }
        } catch (err) {
            console.log(err);
        }
        this.$currentOrganisation.set(organisation);
        this.broadcast.broadcast('OrganisationSettingsChanged');
        this.broadcast.broadcast('KZClearCache');
    }

    public unsetOrganisation(): void {
        this.setOrganisation(null);
    }

    private getApiInfo(easUser: EasUser): ApiInfo {
        return {
            token: `Bearer ${easUser.user.access_token}`,
            organisation: this.$currentOrganisation(),
            username: easUser.usernameHeader,
        };
    }

    public ensureLoggedIn(): Promise<void> {
        return this.easUser().then((user: EasUser) => {
            if (user === null) {
                throw new Error('User not logged in');
            }
        });
    }

    public getApiHeaders(): Promise<ApiInfo> {
        return this.easUser().then((easUser: EasUser) => {
            if (easUser) {
                return this.getApiInfo(easUser);
            }
            throw new Error('User not logged in');
        });
    }
}