import { MFA } from "@fry/system/account/mfa/mfa";

export enum CredentialTypes {
  LOCAL = 'local',
  PROXY = 'proxy',
  API = 'api'
}

export const READABLE_CREDENTIAL_TYPES = {
  [CredentialTypes.LOCAL]: 'Username',
  [CredentialTypes.PROXY]: 'SSO ID',
  [CredentialTypes.API]: 'API'
};

// Base credential model
export interface BaseCredential {
  type: CredentialTypes;
}

// Organization-based credential model
export interface OrgCredential extends BaseCredential {
  organisationId: string;
  username?: string;
}

// Local credential model, including MFA and password management
export interface LocalCredential extends OrgCredential{
  type: CredentialTypes.LOCAL;
  password?: string;  // Only used when adding a new credential and not returned from the API
  passwordNotSet?: boolean; // Used to determine if the password is set
  mfas?: Array<MFA>;
}

// Proxy credential model, includes integration option
export interface ProxyCredential extends OrgCredential {
  type: CredentialTypes.PROXY;
  useIntegration?: boolean;
}

// API credential model, includes public and secret keys
export interface ApiCredential extends BaseCredential {
  type: CredentialTypes.API;
  public_key?: string;
  secret_key?: string; // Only used when adding a new credential and not returned from the API
  audience?: string; // e.g., 'api', 'api_v1', or 'api_v2'
}
