import { Component, computed, inject } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from "@angular/material/card";
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { MatDivider } from "@angular/material/divider";
import { CommonModule } from "@angular/common";
import { MatActionList, MatList, MatListItem } from "@angular/material/list";
import { CredentialService } from "@fry/system/account/credential/crendential.service";
import {
  ApiCredential,
  CredentialTypes,
  LocalCredential,
  ProxyCredential,
  READABLE_CREDENTIAL_TYPES
} from "@fry/system/account/credential/credential";
import { AuthService } from "@fry/core/auth.service";
import {
  LocalCredentialSetupDialogComponent
} from "./dialogs/local-credential-setup-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ProxyCredentialSetupDialogComponent
} from "./dialogs/proxy-credential-setup-dialog.component";
import {
  EditUsernameCredentialDialogComponent
} from "@fry/system/account/credential/dialogs/edit-username-credential-dialog.component";
import {
  ChangePasswordDialogComponent
} from "@fry/system/account/credential/dialogs/change-password-credential-dialog.component";
import { RouteService, upgradeProviders } from '@fry/upgrade/route.service';
import { RouteLinkJsDirective } from '@fry/upgrade/route.link.js.directive';
import { SecurityService } from "@fry/core/security.service";
import { SYSTEM_ROLE_ID } from "@fry/core/roles.system";
import { OrganisationService } from "@fry/core/organisation.service";


@Component({
  selector: 'eas-credentials',
  standalone: true,
  templateUrl: './credentials.component.html',
  imports: [
    CommonModule,
    MatCard,
    MatCardTitle,
    MatCardHeader,
    MatTabGroup,
    MatTab,
    MatCardContent,
    MatDivider,
    MatList,
    MatActionList,
    MatListItem,
    RouteLinkJsDirective,
  ],
  providers: [
    ...upgradeProviders,
  ]
})
export class CredentialsComponent {
  private routeService = inject(RouteService);

  public remoteUser = this.routeService.getParam('remoteUser');
  public isUserRemote = computed(() => !!this.remoteUser());
  public accountId = computed(() => this.remoteUser() || this.authService.currentUser());
  public isUserAdmin: boolean = false;

  allowedAccountTypes = [];
  loginCredentials: (LocalCredential | ProxyCredential)[] = [];
  apiCredentials: ApiCredential[] = [];
  readableName = READABLE_CREDENTIAL_TYPES;

  newlyCreatedAPICredential: ApiCredential;

  protected readonly CredentialTypes = CredentialTypes;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private organisationService: OrganisationService,
    private securityService: SecurityService,
    private credentialService: CredentialService,
  ) {
    this.setIsSuperAdmin();
  }

  ngOnInit() {
    this.loadCredentials();
    this.setIsSuperAdmin();
  }

  private async loadCredentials() {
    const response = await this.credentialService.getCredentials(this.accountId())
    if (response.state !== "ok") {
      return;
    }

    this.loginCredentials = response.data.filter(cred => cred.type !== CredentialTypes.API) as (LocalCredential | ProxyCredential)[];
    this.apiCredentials = response.data.filter(cred => cred.type === CredentialTypes.API) as ApiCredential[];
  }

  async setIsSuperAdmin() {
    this.isUserAdmin = await this.securityService.hasRole(SYSTEM_ROLE_ID.SUPER_ADMIN);
    let allowedAccountTypes = this.organisationService.currentOrganisation().allowedAccountTypes;
    if (!this.isUserAdmin) {
      allowedAccountTypes = allowedAccountTypes.filter(type => type !== CredentialTypes.PROXY);
    }
    this.allowedAccountTypes = allowedAccountTypes;
  }

  addCredential(type: CredentialTypes) {
    if (type === CredentialTypes.LOCAL) {
      this.addLocalCredential();
    } else if (type === CredentialTypes.PROXY) {
      this.addProxyCredential();
    }
  }

  private addLocalCredential() {
    const dialogRef = this.dialog.open(LocalCredentialSetupDialogComponent, {
      width: '600px',
      disableClose: true,
      data: { isForSomeoneElse: this.isUserRemote() }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const localCredential: LocalCredential = {
          type: CredentialTypes.LOCAL,
          organisationId: this.authService.currentOrganisation(),
          username: result.username,
          password: result.password
        }
        const response = await this.credentialService.addCredential(this.accountId(), localCredential);
        if (response.state !== "ok") {
          this.snackBar.open('Failed to add credential. Please try again later.', 'Close', {
            duration: 3000,
          });
          return;
        }

        this.snackBar.open('Credential added successfully!', 'Close', { duration: 3000 });
        this.loadCredentials();
      }
    });
  }

  private addProxyCredential() {
    const dialogRef = this.dialog.open(ProxyCredentialSetupDialogComponent, {
      width: '600px',
      disableClose: true,
      data: { isForSomeoneElse: this.isUserRemote() }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const proxyCredential: ProxyCredential = {
          type: CredentialTypes.PROXY,
          organisationId: this.authService.currentOrganisation(),
          username: result.username
        }
        const response = await this.credentialService.addCredential(this.accountId(), proxyCredential);
        if (response.state !== "ok") {
          this.snackBar.open('Failed to add credential. Please try again later.', 'Close', {
            duration: 3000,
          });
          return;
        }

        this.snackBar.open('Credential added successfully!', 'Close', { duration: 3000 });
        this.loadCredentials();
      }
    });
  }

  async addApiCredential() {
    const apiCredential: ApiCredential = {type: CredentialTypes.API };
    const response = await this.credentialService.addCredential(this.accountId(), apiCredential);
    if (response.state !== "ok") {
      this.snackBar.open('Failed to add API credential. Please try again later.', 'Close', {
        duration: 3000,
      });
      return;
    }

    this.newlyCreatedAPICredential = response.data as ApiCredential;
    this.snackBar.open('Credential added successfully!', 'Close', { duration: 3000 });
    this.loadCredentials();
  }

  copyToClipboard() {
    if (this.newlyCreatedAPICredential) {
      const text = `public_key: ${this.newlyCreatedAPICredential.public_key}\nsecret_key: ${this.newlyCreatedAPICredential.secret_key}`;
      navigator.clipboard.writeText(text).then(() => {
        this.snackBar.open('API credentials copied to clipboard!', 'Close', { duration: 3000 });
      });
    }
  }

  editUsername(credential: LocalCredential | ProxyCredential) {
    const dialogRef = this.dialog.open(EditUsernameCredentialDialogComponent, {
      width: '400px',
      disableClose: true,
      data: { currentCredentialUsername: credential.username, credentialType: credential.type }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const currentCredentialUsername = credential.username;
        const response = await this.credentialService.editCredentialUsername(this.accountId(), credential.type, currentCredentialUsername, result.newUsername);
        if (response.state !== "ok") {
          this.snackBar.open('Failed to update username. Please try again later.', 'Close', {
            duration: 3000,
          });
          return;
        }

        this.snackBar.open('Username updated successfully!', 'Close', { duration: 3000 });
        this.loadCredentials();
      }
    });
  }

  changePassword(localCredential: LocalCredential) {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      data: { username: localCredential.username }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const { oldPassword, newPassword } = result;
        const response = await this.credentialService.changePassword(this.accountId(), localCredential.username, oldPassword, newPassword);
        if (response.state !== "ok") {
          this.snackBar.open('Failed to change password. Please try again later.', 'Close', {
            duration: 3000,
          });
          return;
        }

        this.snackBar.open('Password changed successfully!', 'Close', { duration: 3000 });
      }
    });
  }

  async removeCredential(credential: LocalCredential | ProxyCredential | ApiCredential) {
    var response;
    if (credential.type === CredentialTypes.API) {
      response = await this.credentialService.deleteApiCredential(this.accountId(), credential.public_key);
    } else {
      response = await this.credentialService.deleteLoginCredential(this.accountId(), credential);
    }

    if (response.state !== "ok") {
      this.snackBar.open('Failed to delete credential. Please try again later.', 'Close', {
        duration: 3000,
      });
      return;
    }

    this.snackBar.open('Credential deleted successfully!', 'Close', { duration: 3000 });
    this.loadCredentials();
  }

  // todo: send reset password request
  async sendPasswordEmail(localCredential: LocalCredential) {
    // todo: ask first permission via a pop message
    //     title: 'This will send an email to this user containing a link for them to follow to ' +
    //     'reset their password. Would you like to continue?',
    //     type: 'warning',
    const response = await this.credentialService.sendPasswordResetEmail(
      this.accountId(),
      localCredential.username
    );

    if (response.state !== "ok") {
      this.snackBar.open('Failed. Please try again later.', 'Close', {
        duration: 3000,
      });
      return;
    }

    this.snackBar.open('An email would be sent shortly!', 'Close', { duration: 3000 });
  }
}
