import { Inject, Injectable, signal } from "@angular/core";
import { StateParams, StateService } from "angular-ui-router";


@Injectable()
export class RouteService {

    constructor(
        @Inject('$state') private $state: StateService,
        @Inject('$stateParams') private $stateParams: StateParams
    ) {
    }

    getParams() {
        const params = this.$stateParams;
        return signal(params);
    }

    getParam(name: string) {
        const param = this.$stateParams[name];
        return signal(param);
    }

    navigate(route: string, params: any) {
        this.$state.go(route, params);
    }

    href(route: string, params: any) {
        return this.$state.href(route, params);
    }
}

export const upgradeProviders = [
    {
        provide: '$state',
        useFactory: ($injector: any) => $injector.get('$state'),
        deps: ['$injector']
      },
      {
        provide: '$stateParams',
        useFactory: ($injector: any) => $injector.get('$stateParams'),
        deps: ['$injector']
      },
      RouteService
]