<h2 mat-dialog-title>{{dialogTitle}}</h2>
<form [formGroup]="editUsernameForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>

    <mat-form-field *ngIf="orgHasintegration">
      <mat-label>Would you like to also edit this account in your CRM?</mat-label>
      <input type="checkbox" matInput formControlName="useIntegration" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input matInput formControlName="newUsername" required>
      <mat-error *ngIf="editUsernameForm.get('newUsername').hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="editUsernameForm.invalid">Submit</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</form>
