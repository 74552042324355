import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MFAService } from './mfa.service';
import { CommonModule } from "@angular/common";
import * as QRCode from 'qrcode';


@Component({
  selector: 'eas-totp-registration',
  templateUrl: './totp-registration.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule]
})
export class TotpRegistrationComponent {
  @Input() credentialUsername: string;

  totpForm: FormGroup;
  verificationMessage: string = '';
  uri: string;
  qrCodeDataUrl: string;
  createdMFAId: string;

  constructor(
    private fb: FormBuilder,
    private mfaService: MFAService
  ) {
    this.totpForm = this.fb.group({ code: [''] });
  }

  ngOnInit(): void {
    this.initTOTPRegistration();
  }

  async initTOTPRegistration() {
    const response = await this.mfaService.initTOTPRegistration(
      this.credentialUsername
    )
    if (response.state === "ok") {
      this.uri = response.data.uri;
      this.qrCodeDataUrl = await QRCode.toDataURL(response.data.uri);
      this.createdMFAId = response.data.mfa_id;
    } else {
      console.error('Error sending OTP:', response.error);
      this.verificationMessage = 'Failed to send OTP. Please try again later.';
    }
  }

  async completeTOTPRegistration() {
    const code = this.totpForm.value.code;
    const response = await this.mfaService.completeTOTPRegistration(
      this.credentialUsername,
      this.createdMFAId,
      code
    )
    if (response.state === "ok") {
      this.verificationMessage = 'TOTP verified successfully!';
    } else {
      this.verificationMessage = 'TOTP verification failed. Try again.';
    }
  }

  copyToClipboard() {
    const copyField = document.getElementById('copyField') as HTMLInputElement;
    copyField.select();
    copyField.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');
  }
}
