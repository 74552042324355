import { ApplicationConfig } from '@angular/core';
// import { provideRouter, withDebugTracing, withDisabledInitialNavigation, withRouterConfig } from '@angular/router';

// import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimations } from "@angular/platform-browser/animations";
// import { provideHttpClient } from '@angular/common/http';
// import { ɵprovideZonelessChangeDetection as provideZonelessChangeDetection } from '@angular/core'



export const appConfig: ApplicationConfig = {
  providers: [
    // provideRouter(routes, withDebugTracing(), withRouterConfig({}), withDisabledInitialNavigation()),
    // provideHttpClient(),
    provideServiceWorker('ngsw-worker.js'),
    // provideZonelessChangeDetection()
    provideAnimations()
  ]
};
