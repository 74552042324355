import { Component, Input } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MethodSelectionComponent } from "@fry/system/account/mfa/method-selection.component";
import { EmailRegistrationComponent } from "@fry/system/account/mfa/email-registration.component";
import { TotpRegistrationComponent } from "@fry/system/account/mfa/totp-registration.component";
import {
  PasskeyRegistrationComponent
} from "@fry/system/account/mfa/passkey-registration.component";

@Component({
  selector: 'eas-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  standalone: true,
  imports: [CommonModule, MethodSelectionComponent, EmailRegistrationComponent, TotpRegistrationComponent, PasskeyRegistrationComponent]
})
export class MfaSetupComponent {
  @Input() credentialUsername: string;

  selectedMethod: string = '';
  currentStep: number = 1;

  // Method to handle user's method selection
  onMethodSelected(method: string) {
    this.selectedMethod = method;
    this.nextStep();
  }

  // Method to move to the next step
  nextStep() {
    this.currentStep++;
  }

  // Method to go back to the previous step (if needed)
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  // Reset the setup process
  resetSetup() {
    this.selectedMethod = '';
    this.currentStep = 1;
  }
}
