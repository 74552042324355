import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";


@Component({
  selector: 'app-local-credential-setup-dialog',
  templateUrl: './local-credential-setup-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormField,
    MatSelect,
    MatOption,
    MatDialogActions,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    MatLabel,
    MatError
  ]
})
export class LocalCredentialSetupDialogComponent implements OnInit {
  localForm: FormGroup;
  isForSomeoneElse: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LocalCredentialSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isForSomeoneElse: boolean }
  ) {
    this.isForSomeoneElse = data.isForSomeoneElse;
  }

  ngOnInit(): void {
    this.localForm = this.fb.group({
      username: ['', Validators.required],
      passwordOptions: [],
      password: ['']
    });

    // Show password field only if "isForSomeoneElse" is false or "passwordOptions" is "customPassword"
    this.updatePasswordFieldVisibility();

    // Update password field visibility when passwordOptions changes
    this.localForm.get('passwordOptions').valueChanges.subscribe(() => {
      this.updatePasswordFieldVisibility();
    });
  }

  updatePasswordFieldVisibility() {
    const passwordOptions = this.localForm.get('passwordOptions').value;
    if (this.isForSomeoneElse && passwordOptions === 'customPassword') {
      this.localForm.get('password').setValidators([Validators.required, Validators.minLength(12), this.strongPasswordValidator]);
    } else {
      this.localForm.get('password').clearValidators();
    }
    this.localForm.get('password').updateValueAndValidity();
  }

  strongPasswordValidator(control) {
    const value = control.value || '';
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecial = /[!?=#*$@+-]/.test(value);
    return hasUpperCase && hasLowerCase && hasNumeric && hasSpecial ? null : { weakPassword: true };
  }

  onSubmit() {
    if (this.localForm.valid) {
      this.dialogRef.close(this.localForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
