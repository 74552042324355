import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";

@Component({
  selector: 'app-change-password-credential-dialog',
  templateUrl: './change-password-credential-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    MatLabel,
    MatError,
    MatHint,
    MatDialogActions
  ]
})
export class ChangePasswordDialogComponent implements OnInit {
  changePasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { username: string }
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, this.passwordsShouldNotMatch('oldPassword')]],
      confirmPassword: ['', [Validators.required, this.passwordsShouldMatch('newPassword')]]
    });
  }

  passwordsShouldMatch(matchTo: string) {
    return (control: AbstractControl) => {
      return control?.parent?.controls[matchTo].value === control.value ? null : { mismatch: true };
    };
  }

  passwordsShouldNotMatch(matchTo: string) {
    return (control: AbstractControl) => {
      return control?.parent?.controls[matchTo].value !== control.value ? null : { samePassword: true };
    };
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.dialogRef.close(this.changePasswordForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
