(function() {
  'use strict';

  function OrgChooserController($location, LocalUsers, LocalStore, Database,
    Auth, Cache, Api, Network, Setup, ClusterService, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Choose your organisation');

    Database.cancel();
    Auth.unsetOrganisation();

    ctrl.offline = Network.isOffline();

    function updateOrgWithLocalData(orgs) {
      _.forEach(orgs, function(org) {
        LocalStore.count({ organisation: org.id })
          .then(function(count) {
            org.count = count;
          })
          .catch(function(err) {
            console.log('Could not fetch number of items for', org.id, err);
          });
      });
    }
    var prom;
    if (Network.isOffline()) {
      prom = LocalUsers.getOrganisationsFor(Auth.currentUser());
    } else {
      prom = ClusterService.getProfileOrganisations({ force: true })
        .then(function(orgs) {
          return orgs.orgs;
        });
    }

    // Organisations.getUserOrganisations()
    //   .catch(function(err) {
    //     if (err && err.status === 509) {
    //       return LocalUsers.getOrganisationsFor(Auth.currentUser());
    //     }
    //     return $q.reject(err);
    //   })
    prom
      .then(function(orgs) {
        ctrl.organisations = orgs;
        // Not returning on purpose as it can be easily loaded
        updateOrgWithLocalData(orgs);
        if (orgs.length === 1) {
          ctrl.chooseOrg(orgs[0].id);
        } else {
          ctrl.loaded = true;
        }
      })
      .catch(function(err) {
        ctrl.loaded = true;
        if (err && err.data && err.data.reason) {
          ctrl.error = err.data.reason;
          return;
        }

        console.log('Error when fetching organisations', err);
        ctrl.error = 'Unknown error has occured';
      });

    ctrl.chooseOrg = function(orgId) {
      Cache.destroy();
      Api.purgeCache();
      Auth.setOrganisation(orgId);
      var cameFrom = $location.search().came_from || '';
      $location.url(cameFrom);
    };

    ctrl.logout = function() {
      Setup.logout();
    };
  }

  OrgChooserController.$inject = [
    '$location',
    'LocalUsersService',
    'LocalStoreService',
    'DatabaseService',
    'AuthService',
    'CacheService',
    'ApiService',
    'NetworkService',
    'SetupService',
    'ClusterService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('OrgChooserController', OrgChooserController);
})();
